<template>
    <div>
        <table-and-header
                ref="table"
                :company-list= "companies"
                :is-fetching-data= "isFetchingData"
                :selected="[]"
                @remove="removeCompany"
                @delete="deleteCompany"
                @edit="edit"
                @updateStatus="updateStatus"
                @printAddress="printAddress"
        />
        
        <edit
            v-model="isCompanyHandlerSidebarActive"
            :company="company"
            :clear-company-data="clearCompanyData"
            @update-company="update"
        />
    </div>
</template>

<script>
import { avatarText } from '@core/utils/filter';
import { useRouter } from '@core/utils/utils';
import { computed, ref, watch } from '@vue/composition-api';
import TableAndHeader from './TableAndHeader.vue';
import Edit from './Edit.vue';
import Company from '@/models/Company';
import { errorToast, successToast } from '@/views/helpers/toast';
import { NO_SELECTED_IDS, SOMETHING_WENT_WRONG } from '@/constants/errors';


export default {
    components: {
        TableAndHeader,
        Edit
    },

    mounted()
    {
        // just to make sure the menu bar is shown
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
    },

    setup() {

        const selected = [];
        const isBusy = true;
        const isLoading = ref(true);
        const companies = ref([]);
        const isFetchingData = ref(false);

        const { route } = useRouter();
        const routeParams = computed(() => route.value.params);


        const companyName = "";
        const companyAddress = "";

        watch(routeParams, () => {
        // eslint-disable-next-line no-use-before-define
            fetchCompanies();
        });

        const isCompanyHandlerSidebarActive = ref(false);

        const companyBluePrint = {
            id: undefined,
            frequency: undefined,
            uen: undefined,
            entity_name: undefined,
            primary_ssic_description: undefined,
            secondary_ssic_description: undefined,
            registration_incorporation_date: undefined,
            address: undefined,
            postalCode: undefined,
            levelNo: undefined,
            unitNo: undefined,
        };

        const company = ref(JSON.parse(JSON.stringify(companyBluePrint)));

        const setCompany = dataCompany => ({
            id: dataCompany.id,
            frequency: dataCompany.frequency,
            uen: dataCompany.uen,
            entity_name: dataCompany.entity_name,
            primary_ssic_description: dataCompany.primary_ssic_description,
            secondary_ssic_description: dataCompany.secondary_ssic_description,
            registration_incorporation_date: dataCompany.registration_incorporation_date,
            address: dataCompany.address,
            block: dataCompany.block,
            streetName: dataCompany.street_name,
            buildingName: dataCompany.building_name,
            postalCode: dataCompany.postal_code,
            levelNo: dataCompany.level_no,
            unitNo: dataCompany.unit_no,
        });

        const fetchCompanies = async ()=> {
            isFetchingData.value = true;
            companies.value = [];

            const companyList = await Company.all(route.value.meta.status);
            companyList.forEach(dataCompany => {
                const data = setCompany(dataCompany);
                companies.value.push(data);
            });

            isFetchingData.value = false;
        };
        fetchCompanies();

        const edit = data => {
            company.value = {...data.item};
            isCompanyHandlerSidebarActive.value = true;
        };

        const update = data => {
            Company.update(data.id, data)
                .then(() => {
                    fetchCompanies();
                })
                .catch(e => {
                    errorToast(SOMETHING_WENT_WRONG);
                    console.error(
                        "error while trying to update campaign.",
                        e
                    );
                });
        };

        const printAddress = ids => {
            if (ids.length < 1)
                errorToast(NO_SELECTED_IDS);
            else
                Company.printAddress({ "ids" : ids})
                    .then(response => {
                        const blob = new Blob([response.data], {
                            type: 'application/pdf'
                        });
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'companies-address.pdf';
                        link.click();

                        companies.value = companies.value.filter(exitingCompany =>
                            ids.indexOf(exitingCompany.id.toString()) < 0
                        );
                        // fetchCompanies();
                        successToast(`Company addresses printed successfully`);
                    })
                    .catch(e => {
                        errorToast(SOMETHING_WENT_WRONG);
                        console.error(
                            "error while trying to download the PDF.",
                            e
                        );
                    });
        };

        const updateStatus = ad => {
            Company.updateAd(ad.item.id, route.value.meta.status)
                .then(() => {
                    companies.value = companies.value.filter(exitingCompany =>
                    // eslint-disable-next-line no-use-before-define
                        exitingCompany.id !== ad.item.id
                    );
                    successToast(`The company has been updated successfully.`);
                })
                .catch(e => {
                    errorToast(SOMETHING_WENT_WRONG);
                    console.error(
                        "error while trying to update the status of the company.",
                        e
                    );
                });
        };

        const removeCompany = companyToDelete => {
            Company.remove(companyToDelete.item.id, companyToDelete.item, route.value.meta.status)
                .then(() => {
                    companies.value = companies.value.filter( exitingCompany =>
                    // eslint-disable-next-line no-use-before-define
                        exitingCompany.id !== companyToDelete.item.id
                    );
                    successToast("Record has been removed from the campaign successfully.");
                })
                .catch(e => {
                    errorToast(SOMETHING_WENT_WRONG);
                    console.error(
                        "error while trying to delete company.",
                        e
                    );
                });
        };
        
        const deleteCompany = companyToDelete => {
            Company.delete(companyToDelete.item.id)
                .then(() => {
                    companies.value = companies.value.filter( exitingCompany =>
                    // eslint-disable-next-line no-use-before-define
                        exitingCompany.id !== companyToDelete.item.id
                    );
                    successToast("Record has been deleted successfully.");
                })
                .catch(e => {
                    errorToast(SOMETHING_WENT_WRONG);
                    console.error(
                        "error while trying to delete company.",
                        e
                    );
                });
        };
        
        const clearCompanyData = () => {
            company.value = JSON.parse(JSON.stringify(companyBluePrint));
        };


        return {
            fetchCompanies,
            edit,
            update,

            setCompany,
            // Filter
            avatarText,
            removeCompany,
            deleteCompany,
            updateStatus,
            printAddress,
            companyName,
            companyAddress,
            selected,
            clearCompanyData,

            // data
            isBusy,
            isLoading,

            isFetchingData,
            company,
            companies,
            isCompanyHandlerSidebarActive,
        };
    }
};
</script>

<style lang="scss" scoped>

    .hide{
        position:fixed !important;
        height:1px !important;
        width:1px !important;
        bottom:0 !important;
        left:0 !important;
        z-index:-999 !important;
        display:inline !important;
    }

    .per-page-selector {
        width: 90px;
    }

    .table-container {
        position: relative;
        width: 100%;
    }

    .b-table[aria-busy="true"] + .table-spinner {
        /* this assumes that the spinner component has a width and height */
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10; /* make sure spinner is over table */
    }

    .preloader div:before {
        background-color: var(--primary-color) !important;
    }
</style>

<style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
</style>
