
import { ref, watch } from '@vue/composition-api';

export default function useCompanyHandler(props, emit) {
    // ------------------------------------------------
    // taskLocal
    // ------------------------------------------------
    const companyLocal = ref(JSON.parse(JSON.stringify(props.company.value)));

    const resetCompanyLocal = () => {
        companyLocal.value = JSON.parse(JSON.stringify(props.company.value));
    };

    watch(props.company, () => {
        resetCompanyLocal();
    });

    const onSubmit = () => {
        const company = JSON.parse(JSON.stringify(companyLocal));

        // * If event has id => Edit Event
        // Emit event for add/update event
        emit('update-company', company.value);

        // Close sidebar
        emit('update:is-company-handler-sidebar-active', false);
    };


    return {
        companyLocal,
        resetCompanyLocal,

        // UI
        onSubmit,
    };
}
