<template>
    <div>
        <!-- <AppBreadcrumb /> -->
        <!-- Table Container Card -->
        <b-card no-body class="mb-0" >
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col
                            cols="12"
                            :md="(status === 'leads') ? 8 : 9"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <label>Show</label>
                        <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="3">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                    v-model="searchQuery"
                                    class="d-inline-block mr-1"
                                    placeholder="Search..."
                            />
                        </div>

                    </b-col>
                    <b-col v-if="status === 'leads'" cols="12" md="1">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-button
                                    variant="primary"
                                    @click="$emit('printAddress', ids)"
                            >
                            <span class="text-nowrap">
                                Print
                            </span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="ids"
                    name="flavour-2"
                    class="demo-inline-spacing"
            >

            <b-table
                    ref="refListTable"
                    class="position-relative"
                    :items="companyList"
                    :per-page="perPage"
                    :current-page="currentPage"
                    responsive
                    :fields="tableColumns"
                    primary-key="id"
                    busy.sync="isBusy"
            >

                <template  #cell(index)="data" >
                    {{ data.index + 1 }}
                </template>

                <template  #cell(print)="data">
                    <b-form-checkbox :value="`${data.item.id}`">
                    </b-form-checkbox>
                </template>


                <!-- Column: Actions -->
                <template #cell(actions)="data">
                <span class="ml-2 cursor-pointer">
                    <b-dropdown size="sm" menu-class="ml-n2 mt-2" dropright variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon"/>
                        </template>

                        <b-dropdown-item v-if="status != 'acquired'"
                                @click="$emit('updateStatus', data, 'updateStatus')"
                        >
                            <div class="d-flex align-items-center">
                                <feather-icon icon="CheckIcon" />
                                <span style="padding-top: .1em; padding-left: .5em; text-transform: capitalize;">{{ (status == 'leads') ? 'Approached' : (status == 'approached') ? 'response received' :  'acquired'  }}</span>
                            </div>
                        </b-dropdown-item>
                        
                        <b-dropdown-item
                                data-test="deleteCampaignTest"
                                @click="$emit('edit', data, 'edit')"
                        >
                            <div class="d-flex align-items-center">
                                <feather-icon icon="EditIcon" />
                                <span style="padding-top: .1em; padding-left: .5em">Edit</span>
                            </div>
                        </b-dropdown-item>
                        
                        <b-dropdown-item
                                @click="$emit('remove', data, 'remove')"
                        >
                            <div class="d-flex align-items-center">
                                <feather-icon icon="MinusIcon" />
                                <span style="padding-top: .1em; padding-left: .5em">Exclude</span>
                            </div>
                        </b-dropdown-item>

                        <b-dropdown-item
                                data-test="deleteCampaignTest"
                                @click="$emit('delete', data, 'delete')"
                        >
                            <div class="d-flex align-items-center">
                                <feather-icon icon="TrashIcon" />
                                <span style="padding-top: .1em; padding-left: .5em">Inactive</span>
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                </span>
                </template>
            </b-table>

            </b-form-checkbox-group>

            <loader v-if="isFetchingData" data-type="Table" />

            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                            cols="12"
                            sm="6"
                            class="
                        d-flex
                        align-items-center
                        justify-content-center justify-content-sm-start
                    "
                    >
                    <span class="text-muted"
                    >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                        {{ dataMeta.of }} entries</span
                    >
                    </b-col>

                    <!-- Pagination -->
                    <b-col
                            cols="12"
                            sm="6"
                            class="
                        d-flex
                        align-items-center
                        justify-content-center justify-content-sm-end
                    "
                    >
                        <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>

                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <confirmation-modal  ref="modal">
            <template slot="body">
                <div>
                    <feather-icon class="d-block mx-auto text-warning" icon="AlertCircleIcon" size="90"/>
                    <h1 class="text-center text-secondary display-5 mt-2">Are you sure?</h1>
                    <p class="text-center mt-2"> You won't be able to revert this! </p>
                </div>
            </template>

            <template slot="footer">
                <b-button size="md" variant="primary" @click="confirmDelete">
                    Yes, delete it!
                </b-button>

                <b-button size="md" variant="outline-danger" @click="cancelDelete">
                    Cancel
                </b-button>
            </template>
        </confirmation-modal>

    </div>
</template>

<script>
import { BFormCheckbox, BFormCheckboxGroup, BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from '@vue/composition-api';
import { useRouter } from '@core/utils/utils';
import useTableHandler from '../useTableHandler';
import ConfirmationModal from '@/views/components/utils/ConfirmationModal.vue';


export default {

    components: {
        // AppBreadcrumb,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        ConfirmationModal,

        BFormCheckbox,
        BFormCheckboxGroup,

        vSelect,
    },


    props:{
        companyList:{
            required:true,
            type:Array
        },

        isFetchingData: {
            type: Boolean,
            required: true
        },

        selected: {
            type: Array
        }
    },

    data() {

        return {
            toDelete:null,
        };
    },

    watch: {
        $route(to) {
            this.status = to.meta.status;
            this.ids = [];
            if (this.status && this.status !== "leads")
            {
                this.tableColumns = this.tableColumns.filter(column =>
                    column.key !== "print" && column.key !== "frequency"
                );
            }else {
                this.tableColumns = this.allTableColumns;
            }
        },

        selected() {
            this.ids = [];
        }
    },

    methods:{
        dataToDelete(data){
            this.$refs.modal.$refs.confirmationModal.show();
            this.toDelete= data;
        },

        confirmDelete(){
            this.$emit('removeCompany', this.toDelete);
            this.toDelete = null;
            this.$refs.modal.$refs.confirmationModal.hide();
        },

        cancelDelete(){
            this.toDelete = null;
            this.$refs.modal.$refs.confirmationModal.hide();
        }
    },


    setup(props) {
        const isBusy = true;
        const isLoading = ref(true);

        const { route } = useRouter();
        const { status } = route.value.meta;

        const ids = props.selected;

        const allTableColumns = [
            {key: 'index', label: '#', thClass: 'px-1', tdClass: 'px-1', width: { width: "10%" }, tdStyle: { width: "10%" }},
            (status === "leads" || this.status === undefined) ? { key: 'print', label: 'Print', sortable: false, tdClass: 'px-0', thClass: 'px-0', thStyle: { width: "10%" } }: "",
            { key: 'actions', label: 'ACTION', thClass: 'px-0 text-right', tdClass: 'px-0 text-right', thStyle: { width: "10%" }, tdStyle: { width: "10%" } },
            { key: 'uen', label: 'UEN', thClass: 'px-2', tdClass: 'px-2', sortable: true, thStyle: { width: "10%" }, tdStyle: { width: "10%" } },
            { key: 'entity_name', label: 'Name',sortable: true, thStyle: { width: "10%" }, tdStyle: { width: "10%" } },
            { key: 'address', label: 'Address', sortable: true, thStyle: { width: "10%" }, tdStyle: { width: "10%" } },
            { key: 'primary_ssic_description', label: 'Primary SSIC', sortable: true, thStyle: { width: "10%" }, tdStyle: { width: "10%" } },
            { key: 'secondary_ssic_description', label: 'Secondary SSIC', sortable: true, thStyle: { width: "10%" }, tdStyle: { width: "10%" } },
            { key: 'registration_incorporation_date', thClass: '', tdClass: '', label: 'Registration Date', sortable: true, thStyle: { width: "24%", whiteSpace: "nowrap" }, tdStyle: { width: "24%" } },
            (status === "leads" || this.status === undefined) ? { key: 'frequency', label: 'Frequency', sortable: false, thClass: 'px-1', tdClass: 'px-1', thStyle: { width: "10%" }, tdStyle: { width: "10%" } }: "",
        ];

        const tableColumns = allTableColumns;

        const {
            perPage,
            currentPage,
            totalRows,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            refetchData,

            // UI
            resolveShareTypeRoleVariant,
            resolveShareTypeRoleIcon,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter
        } = useTableHandler(isLoading);


        return {
            ids,
            tableColumns,
            perPage,
            currentPage,
            totalRows,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            refetchData,

            // UI
            resolveShareTypeRoleVariant,
            resolveShareTypeRoleIcon,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            status,
            allTableColumns,

            // data
            isBusy,
            isLoading,
        };

    },
};
</script>

<style scoped>

</style>
