<template>
    <div>
        <b-sidebar
            id="sidebar-task-handler"
            sidebar-class="sidebar-lg"
            :visible="isCompanyHandlerSidebarActive"
            bg-variant="white"
            shadow
            backdrop
            no-header
            right
            @change="
                (val) => $emit('update:is-campaign-handler-sidebar-active', val)
            "
            @hidden="clearForm"
        >
            <template #default="{ hide }">
                <!-- Header -->
                <div
                    class="
                        d-flex
                        justify-content-between
                        align-items-center
                        content-sidebar-header
                        px-2
                        py-1
                        side-bar-header
                    "
                >
                    <h3 class="mb-0">
                        {{ companyLocal.id ? "Update Campaign" : "Add Campaign" }}
                    </h3>

                    <div>
                        <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="XIcon"
                            size="16"
                            @click="hide"
                        />
                    </div>
                </div>

                <!-- Body -->
                <validation-observer
                    #default="{ handleSubmit }"
                    ref="refFormObserver"
                >
                    <!-- Form -->
                    <b-form
                        class="p-2"
                        @submit.prevent="handleSubmit(onSubmit)"
                        @reset.prevent="resetForm"
                    >
                        <!-- Title -->
                        <validation-provider
                            #default="validationContext"
                            name="block"
                            rules="required"
                        >
                            <b-form-group label="Block" label-for="block">
                                <b-form-input
                                    id="postal-code"
                                    v-model="companyLocal.block"
                                    autofocus
                                    :state="
                                        getValidationState(validationContext)
                                    "
                                    trim
                                    placeholder="Enter Block"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        
                        <validation-provider
                            #default="validationContext"
                            name="streetName"
                            rules="required"
                        >
                            <b-form-group label="Street Name" label-for="streetName">
                                <b-form-input
                                    id="street-name"
                                    v-model="companyLocal.streetName"
                                    autofocus
                                    :state="
                                        getValidationState(validationContext)
                                    "
                                    trim
                                    placeholder="Enter Street Name"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider
                            #default="validationContext"
                            name="postalCode"
                            rules="required"
                        >
                            <b-form-group label="Postal Code" label-for="postalCode">
                                <b-form-input
                                    id="postal-code"
                                    v-model="companyLocal.postalCode"
                                    data-test ="postal-code"
                                    autofocus
                                    :state="
                                        getValidationState(validationContext)
                                    "
                                    trim
                                    placeholder="Enter Postal Code"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        
                        <validation-provider
                            #default="validationContext"
                            name="levelNumber"
                        >
                            <b-form-group label="Level Number" label-for="levelNumber">
                                <b-form-input
                                    id="postal-code"
                                    v-model="companyLocal.levelNo"
                                    data-test ="unit-number"
                                    autofocus
                                    :state="
                                        getValidationState(validationContext)
                                    "
                                    trim
                                    placeholder="Enter Level Number"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        
                        <validation-provider
                            #default="validationContext"
                            name="unitNumber"
                        >
                            <b-form-group label="Unit Number" label-for="unitNumber">
                                <b-form-input
                                    id="postal-code"
                                    v-model="companyLocal.unitNo"
                                    data-test ="unit-number"
                                    autofocus
                                    :state="
                                        getValidationState(validationContext)
                                    "
                                    trim
                                    placeholder="Enter Unit Number"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        
                        <validation-provider
                            #default="validationContext"
                            name="buildingName"
                        >
                            <b-form-group label="Building Name" label-for="buildingName">
                                <b-form-input
                                    id="postal-code"
                                    v-model="companyLocal.buildingName"
                                    data-test ="unit-number"
                                    autofocus
                                    :state="
                                        getValidationState(validationContext)
                                    "
                                    trim
                                    placeholder="Enter Unit Number"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>


                        <!-- Form Actions -->
                        <div class="d-flex">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="
                                    mr-1
                                    mt-1
                                    d-flex
                                    justify-content-center
                                    align-items-center
                                "
                                type="submit"
                                data-test="create-campaign-btn"
                                >
                                {{ companyLocal.id ? "Update" : "Add " }}

                                <img
                                    v-if="companyLocal.id ? '' : 'Add'"
                                    :src="'/illustrations/add-circle.svg'"
                                    class="ml-1"
                                    alt="add-logo"
                                />

                                <img
                                    v-if="companyLocal.id ? 'Update' : ''"
                                    :src="'/illustrations/edit.svg'"
                                    class="ml-1"
                                    alt="edit-logo"
                                />
                            </b-button>

                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                class="
                                mt-1
                                d-flex
                                justify-content-center
                                align-items-center
                                "
                                variant="outline-primary"
                                @click="$emit('update:is-company-handler-sidebar-active', false)"
                                >Cancel
                                <img
                                    :src="'/illustrations/cancel-circle.svg'"
                                    class="float-right ml-1"
                                    alt="cancel-logo"
                                />
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { toRefs } from "@vue/composition-api";
import useCompanyHandler from './useCompanyHandler';

export default {
    components: {
        // BSV
        BButton,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isCompanyHandlerSidebarActive",
        event: "update:is-company-handler-sidebar-active",
    },
    props: {
        isCompanyHandlerSidebarActive: {
            type: Boolean,
            required: true,
        },

        company: {
            type: Object,
            required: true,
        },

        clearCompanyData: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            required,
            email,
            datePickerConfig: {
                altInput: true,
                altFormat   : "d M Y",
                dateFormat  : "Y-m-d",
            },
        };
    },

    setup(props, { emit }) {
        const {
            companyLocal,
            // UI
            onSubmit,
        } = useCompanyHandler(toRefs(props), emit);
        
        const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(companyLocal, props.clearCompanyData);

        return {
            // Add New
            companyLocal,
            onSubmit,

            // Form Validation
            resetForm,
            clearForm,
            refFormObserver,
            getValidationState,
        };
    },
};
</script>

<style scope>
.side-bar-header{
   background: #f6f6f6 !important;
 }
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
